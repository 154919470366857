import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import theme from 'themes/default.theme';
import GlobalStyles from 'themes/global.style';
import AuthProvider from 'context/AuthProvider';
import AppRoutes from './router';
import 'antd/dist/reset.css';
import { FilterProvider } from 'context/filterContext';
import { HelmetProvider } from 'react-helmet-async';

const App = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyles />
    <BrowserRouter>
      <HelmetProvider>
        <AuthProvider>
          <FilterProvider>
            <AppRoutes />
          </FilterProvider>
        </AuthProvider>
      </HelmetProvider>
    </BrowserRouter>
  </ThemeProvider>
);

const root = createRoot(document.getElementById('root'));
root.render(<App />);
