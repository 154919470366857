export function createUrl(urlData) {
  const keys = Object.keys(urlData);
  let search = '?';
  keys.forEach((key) => {
    if (urlData[key] !== null && urlData[key] !== '') {
      search += `${key}=${urlData[key]}&`;
    }
  });
  return search.substring(0, search.length - 1);
}

export function getUrl(location) {
  const data = location.search
    ? location.search.slice(location.search.indexOf('?') + 1).split('&')
    : [];
  const urlData = {};
  data.forEach((data) => {
    try {
      data = data.split('=');
      const dataVal = decodeURIComponent(data[1]);
      urlData[data[0]] = dataVal;
    } catch (e) {}
  });
  return urlData;
}
export function setStateToUrl(state) {
  let urlData = {};
  for (const key in state) {
    if (state.hasOwnProperty(key)) {
      switch (key) {
        case 'date_range':
          let data = Object.values(state[key]);
          if (data[0] === null && data[1] === null) {
            data = '';
          }
          urlData[key] = data && data.length ? data.join() : null;
          break;
        case 'amenities':
          urlData[key] =
            state[key] && state[key].length ? state[key].join() : null;
          break;
        case 'room':
          if (state[key]) {
            urlData[key] = state[key] ? state[key] : 0;
          } else {
            urlData[key] = '';
          }
          break;
        case 'guest':
          if (state[key]) {
            urlData[key] = state[key] ? state[key] : 0;
          } else {
            urlData[key] = '';
          }
          break;
        case 'property':
          urlData[key] =
            state[key] && state[key].length ? state[key].join() : null;
          break;
        case 'price':
          urlData[key] =
            state[key] && state[key].length ? state[key].join() : null;
          break;

        case 'location':
          if (state[key]) {
            // Check if name exists
            if (state[key].name) {
              urlData['name'] = state[key].name;
            }
            // If name doesn't exist, check for street
            if (state[key].street) {
              urlData[`${key}_street`] = state[key].street;
            }
            // If street doesn't exist, check for neighborhood
            else if (state[key].neighborhood) {
              urlData[`${key}_neighborhood`] = state[key].neighborhood;
            }
            // If neighborhood doesn't exist, check for city
            else if (state[key].city) {
              urlData[`${key}_city`] = state[key].city;
            }
            // If city doesn't exist, check for country
            else if (state[key].country) {
              urlData[`${key}_country`] = state[key].country;
            }
          }
          break;

        case 'reset':
          urlData = state[key];
          break;

        default:
          urlData[key] = state[key];
          break;
      }
    }
  }
  return createUrl(urlData);
}

export function getStateFromUrl(location) {
  const urlData = getUrl(location);
  const state = {};
  for (const key in urlData) {
    if (urlData.hasOwnProperty(key)) {
      switch (key) {
        case 'name':
          // If 'name' exists, add it to the location object
          state['location'] = state['location'] || {};
          state['location']['name'] = urlData[key];
          break;

        case 'location_street':
          state['location'] = state['location'] || {};
          state['location']['street'] = urlData[key];
          break;

        case 'location_neighborhood':
          state['location'] = state['location'] || {};
          state['location']['neighborhood'] = urlData[key];
          break;

        case 'location_city':
          state['location'] = state['location'] || {};
          state['location']['city'] = urlData[key];
          break;

        case 'location_country':
          state['location'] = state['location'] || {};
          state['location']['country'] = urlData[key];
          break;

        // Handle other fields (like date_range, amenities, etc.)
        default:
          state[key] = urlData[key];
          break;
      }
    }
  }
  return state;
}
