export function mapDataHelper(infoValue) {
  const tempMapArray = [];

  console.log("info value received:", infoValue);
  if (infoValue && infoValue.length !== 0) {
    infoValue.forEach(({ place_id, address_components, formatted_address, geometry: { location }, name }) => {
      let id = '';
      let lat = '';
      let lng = '';
      let formattedAddress = '';
      let city = '';
      let neighborhood = '';
      let street = '';
      let propertyName = '';
      let state_long = '';
      let state_short = '';
      let zipcode = '';
      let country_long = '';
      let country_short = '';

      id = place_id;
      lat = location.lat();
      lng = location.lng();
      formattedAddress = formatted_address;
      if (address_components) {
        for (let i = 0; i < address_components.length; i++) {
          if (address_components[i].types.length) {
            switch (address_components[i].types[0]) {
              case 'locality':
                city = address_components[i].long_name;
                break;
                case 'sublocality_level_1' || 'sublocality':
                  neighborhood = address_components[i].long_name;
                  break;             
                case '':
                state_long = address_components[i].long_name;
                state_short = address_components[i].short_name;
                break;
              case 'country':
                country_long = address_components[i].long_name;
                country_short = address_components[i].short_name;
                break;
                case 'premise':
                  propertyName = address_components[i].long_name ||infoValue.name;
                  break;
                  case 'route':
                    street = address_components[i].long_name;
                    break;
              case 'postal_code':
                zipcode = address_components[i].long_name;
                break;
              default:
                break;
            }
          }
        }
      }

      const tempMapObj = {
        id,
        lat,
        lng,
        formattedAddress,
        city,
        propertyName,
        street,
        neighborhood,
        state_short,
        state_long,
        zipcode,
        country_short,
        country_long,
        name // Add the name field here
      };

      tempMapArray.push(tempMapObj);
    });
  }

  return tempMapArray;
}
